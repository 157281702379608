import React from 'react'

const PrivacyPage = () => (
  <div>
    <h2>Datenschutz</h2>
    <p>
      Datenschutz hat für uns einen hohen Stellenwert – für Sie sicherlich auch.
      Mit dieser Datenschutzerklärung geben wir Ihnen bekannt, welche
      personenbezogenen Daten (im Folgenden auch „Daten“) von uns in Bezug auf
      unsere Internetpräsenzen (im Folgenden „Webseiten“) verarbeitet werden und
      welche Rechte Ihnen zustehen. Die Datenschutzerklärung dient auch zur
      Umsetzung unserer Verpflichtungen aus § 13 Telemediengesetz (TMG) sowie
      Artikel 13 der Verordnung (EU) 2016/679 des europäischen Parlaments und
      des Rates vom 27. April 2016 zum Schutz natürlicher Personen bei der
      Verarbeitung personenbezogener Daten, zum freien Datenverkehr und zur
      Aufhebung der Richtlinie 95/46/EG (Datenschutz-Grundverordnung, DSGVO).
    </p>
    <h3>1. Datenschutzbeauftragter</h3>
    <p>
      Bei Fragen zu unserer Verarbeitung Ihrer Daten wenden Sie sich an unseren
      Datenschutzbeauftragten; Sie erreichen ihn unter
    </p>
    <p>
      ??
      <br />
      ??straße ??
      <br />
      ?? Berlin
      <br />
      +49 ??? ???????
      <br />
    </p>
    <p>
      E-Mail: <a href="mailto:??@??.??">??@??.??</a>
    </p>
    <h3>2. Definitionen</h3>
    <p>
      Die Datenschutzerklärung verwendet folgende Begriffe im Sinne der
      Datenschutz-Grundverordnung:
    </p>
    <ul>
      <li>
        „personenbezogene Daten“: alle Informationen, die sich auf eine
        identifizierte oder identifizierbare natürliche Person (im Folgenden
        „betroffene Person“) beziehen; als identifizierbar wird eine natürliche
        Person angesehen, die direkt oder indirekt, insbesondere mittels
        Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
        Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren
        besonderen Merkmalen identifiziert werden kann, die Ausdruck der
        physischen, physiologischen, genetischen, psychischen, wirtschaftlichen,
        kulturellen oder sozialen Identität dieser natürlichen Person sind;
      </li>
      <li>
        „Verarbeitung“ jeden mit oder ohne Hilfe automatisierter Verfahren
        ausgeführten Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
        personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation,
        das Ordnen, die Speicherung, die Anpassung oder Veränderung, das
        Auslesen, das Abfragen, die Verwendung, die Offenlegung durch
        Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den
        Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die
        Vernichtung;
      </li>
      <li>
        „Verantwortlicher“ die natürliche oder juristische Person, Behörde,
        Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen
        über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
        entscheidet;
      </li>
      <li>
        „Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde,
        Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag
        des Verantwortlichen verarbeitet;
      </li>
      <li>
        „Empfänger“ eine natürliche oder juristische Person, Behörde,
        Einrichtung oder andere Stelle, denen personenbezogene Daten offengelegt
        werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt
        oder nicht. Behörden, die im Rahmen eines bestimmten
        Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
        Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten
        jedoch nicht als Empfänger;
      </li>
      <li>
        die Verarbeitung dieser Daten durch die genannten Behörden erfolgt im
        Einklang mit den geltenden Datenschutzvorschriften gemäß den Zwecken der
        Verarbeitung;
      </li>
      <li>
        „Dritter“ eine natürliche oder juristische Person, Behörde, Einrichtung
        oder andere Stelle, außer der betroffenen Person, dem Verantwortlichen,
        dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren
        Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt
        sind, die personenbezogenen Daten zu verarbeiten;
      </li>
      <li>
        „Einwilligung“ der betroffenen Person jede freiwillig für den bestimmten
        Fall, in informierter Weise und unmissverständlich abgegebene
        Willensbekundung in Form einer Erklärung oder einer sonstigen
        eindeutigen bestätigenden Handlung, mit der die betroffene Person zu
        verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden
        personenbezogenen Daten einverstanden ist;
      </li>
      <li>
        „grenzüberschreitende Verarbeitung“ entweder eine Verarbeitung
        personenbezogener Daten, die im Rahmen der Tätigkeiten von
        Niederlassungen eines Verantwortlichen oder eines Auftragsverarbeiters
        in der Union in mehr als einem Mitgliedstaat erfolgt, wenn der
        Verantwortliche oder Auftragsverarbeiter in mehr als einem Mitgliedstaat
        niedergelassen ist, oder eine Verarbeitung personenbezogener Daten, die
        im Rahmen der Tätigkeiten einer einzelnen Niederlassung eines
        Verantwortlichen oder eines Auftragsverarbeiters in der Union erfolgt,
        die jedoch erhebliche Auswirkungen auf betroffene Personen in mehr als
        einem Mitgliedstaat hat oder haben kann;
      </li>
    </ul>

    <h3>3. Art, Umfang und Zweck der Verarbeitung, Rechtsgrundlagen</h3>

    <h4>3.1 Folgende Arten von Daten werden von uns verarbeitet:</h4>

    <ul>
      <li>
        Kundendaten (Namen, Geschlecht, Geburtsdatum, Adresse, Telefon und
        Telefax, E-Mail, Kontoverbindung);
      </li>
      <li>Inhalte (Texte, Bilder, Videos);</li>
      <li>
        Nutzungsdaten (besuchte Webseiten, Zugriffszeiten, Standort, etc.).
      </li>
      <li>Kommunikationsdaten (Geräteinformationen, IP-Adressen, etc.).</li>
      <li>Vertragsdaten (Vertragstext, Zahlungen)</li>
    </ul>
    <h4>3.2 Folgende Kategorien betroffener Personen liegen vor:</h4>
    <p>
      Besucher unserer Webseiten (im Folgenden auch „Nutzer“) sowie andere
      Interessenten, Gewinnspielteilnehmer;
      <br />
      Käufer unserer Waren und Abnehmer von Dienstleistungen (im Folgenden auch
      „Kunden“); weitere Geschäftspartner.
    </p>
    <h4>
      3.3 Die Verarbeitung der Daten erfolgt zu den folgenden Zwecken, und unter
      Nutzung der nachfolgend genannten Rechtsgrundlagen:
    </h4>
    <ul>
      <li>
        Darstellung, Aufrechterhaltung und Verbesserung unserer Webseiten
        einschließlich aller Funktionen gegenüber Nutzern, Beweiszwecke; dies
        erfolgt auf Grundlage von Artikel 6 Absatz 1 Buchstabe f DSGVO (Wahrung
        unserer berechtigten Interessen). Es werden Kommunikations- und
        Nutzungsdaten verarbeitet, eine Weitergabe der Daten an Dritte erfolgt
        grundsätzlich nicht, es sei denn, es besteht hierzu eine gesetzliche
        Verpflichtung (Artikel 6 Absatz 1 Buchstabe c DSGVO).
      </li>
      <li>
        Verarbeitung von Nutzungsdaten (besuchten Webseiten, angesehene
        Produkte) und Inhalte für Werbezwecke, insbesondere für personalisierte
        Produkthinweise; dies erfolgt auf Grundlage von Artikel 6 Absatz 1
        Buchstabe f DSGVO (Wahrung unserer berechtigten Interessen).
      </li>
      <li>
        Antworten auf Anfragen über ein Kontaktformular, E-Mail-Korrespondenz
        mit Nutzern und Kunden, Ausführung eines Gewinnspiels; die Verarbeitung
        erfolgt auf Grundlage von Artikel 6 Absatz 1 Buchstabe b DSGVO.
      </li>
      <li>
        Erstellen eines Benutzerkontos. Anlässlich der Erstellung oder Änderung
        eines Benutzerkontos für unsere Webseiten werden Kunden-, Vertragsdaten
        sowie ggf. Inhalte verarbeitet, um die Leistungen im Rahmen der
        Webseiten für registrierte Nutzer erbringen zu können, Artikel 6 Absatz
        1 Buchstabe b DSGVO; darüber hinaus können Kommunikationsdaten zu
        Beweiszwecken und zum Schutz vor Missbrauch der Funktionen verarbeitet
        werden, Artikel 6 Absatz 1 Buchstabe f DSGVO (Wahrung unserer
        berechtigten Interessen).
      </li>
      <li>
        Zur Ausführung von vertraglichen Verpflichtungen gegenüber Kunden und
        anderen Vertragspartnern werden Kundendaten sowie Vertragsdaten
        verarbeitet. Soweit in Formularen einzugebende Daten als verpflichtend
        gekennzeichnet sind, sind sie zur Erfüllung des dargestellten Zwecks
        erforderlich. Die Verarbeitung erfolgt auf Grundlage von Artikel 6
        Absatz 1 Buchstaben b und c DSGVO.
      </li>
      <li>
        Zur Darstellung eigener Inhalte des Nutzers. Ggf. kann der Nutzer eigene
        Inhalte in Foren oder ähnlichen Funktionen auf unseren Webseiten
        einstellen; dies wird grundsätzlich anonym ermöglicht. Es werden
        IP-Adressen aus Beweisgründen und damit auf Grundlage unserer
        berechtigten Interessen nach Artikel 6 Absatz 1 Buchstabe f DSGVO
        gespeichert.
      </li>
      <li>
        Eigenes Marketing; bei eingeholter Einwilligung erfolgte die
        Verarbeitung gemäß Artikel 6 Absatz 1 Buchstabe a sowie Art. 7 DSGVO, im
        Übrigen zur Wahrung unserer berechtigten Interessen: Artikel 6 Absatz 1
        Buchstabe f DSGVO.
      </li>
    </ul>
    <p>
      Sofern unserer Verarbeitung weitere Rechtsgrundlagen zugrunde liegen,
      werden sie unten in den weiteren Ausführungen benannt.
    </p>
    <h3>4. Empfänger von Daten, Drittländer</h3>
    <p>
      Soweit dies zur Lieferung der von Kunden bestellten Produkte erforderlich
      ist, werden Daten an das von uns mit der Lieferung beauftragte
      Versandunternehmen weitergegeben. Zur Zahlungsabwicklung werden von uns
      die im Zahlungsverkehr erforderlichen Daten (Name, Kontodaten,
      E-Mail-Adresse, Kaufpreis) ggf. an einen Zahlungsdienstleister und/oder an
      ein mit der Zahlung beauftragtes Kreditinstitut bzw. PayPal weitergegeben.
      Weitere Kategorien von Empfänger sind Hostinganbieter, Beteiligte am
      Warenwirtschaft- und Finanzbuchhaltungssystem, externe Anbieter zum
      Kundenservice und ggf. Lieferanten.
    </p>
    <p>
      Die Weitergabe sowie die Offenlegung von Daten gegenüber Empfängern,
      Auftragsverarbeitern oder Dritten erfolgt ausschließlich im Rahmen der
      Rechtsgrundlagen (vgl. oben Ziffer 2.4) oder wenn eine weitergehende
      rechtliche Verpflichtung besteht. Die Gewährung eines Zugriffs auf Daten
      für Auftragsverarbeiter erfolgte unter strikter Beachtung von Artikel 28
      DSGVO. Verarbeitungen der Daten in einem Drittland (außerhalb der
      Europäischen Union (EU) bzw. Europäischer Wirtschaftsraum (EWR) erfolgen
      gemäß der Artikel 44 bis 50 DSGVO. Die Verarbeitung erfolgt auf einem
      Datenschutzniveau, das der DSGVO entspricht, insbesondere durch Garantien
      der Auftragsverarbeiter, z.B. aufgrund der Absprache zwischen EU und den
      USA gemäß des US-Datenschutzschilds (im Folgenden auch „Privacy Shield“),
      oder aufgrund besonderer vertraglicher Verpflichtungen
      (Standardvertragsklauseln).
    </p>
    <h3>5. Datenlöschung</h3>
    <p>
      Eine Löschung von Daten erfolgt auf der Grundlage der Art. 17 und 18
      DSGVO; gleiches gilt für eine Einschränkung der Verarbeitung und das
      Sperren von Daten. Die Löschung oder eine eingeschränkte Verarbeitung der
      Daten erfolgt, wenn und soweit sie zum Erreichen eines angegebenen Zwecks
      nicht mehr erforderlich sind, es sei denn, eine Löschung ist gesetzlich
      (z.B. Aufbewahrungspflichten nach Handels- oder Steuerrecht) untersagt
      oder es ist etwas anderes vereinbart.
    </p>
    <p>
      Nach § 257 Handelsgesetzbuch (HGB) und § 147 Absatz 1 der Abgabenordnung
      (AO) ist jeder Kaufmann insbesondere verpflichtet, Handelsbücher und
      Aufzeichnungen, Inventare, Eröffnungsbilanzen samt Arbeitsanweisungen,
      Jahresabschlüsse, sonstigen Organisationsunterlagen sowie Buchungsbelege
      für zehn Jahre aufzubewahren; für Handels- und Geschäftsbriefe gilt eine
      Frist von sechs Jahren.
    </p>
    <h3>6. Hosting</h3>
    <p>
      Wir arbeiten zur Aufrechterhaltung, Wiederherstellung und Verbesserung
      unserer Leistungen mit Hosting-Partnern zusammen, insbesondere in Bezug
      auf Speicherplatz, Rechenkapazität, Datenbank, Infrastruktur,
      Wartungsleistungen und ähnliche Dienste. Hierbei kann es zur Verarbeitung
      von Daten nach Ziffer 2.1 dieser Datenschutzerklärung kommen; insbesondere
      zur Erhebung von Serverlogfiles (Serverzugriffe). Die Verarbeitung erfolgt
      auf der Grundlage eines berechtigten Interesses auf unserer Seite gemäß
      Artikel 6 Absatz 1 Buchstabe f DSGVO i.V.m. Art. 28 DSGVO. Eine Löschung
      der Daten erfolgt spätestens sieben Tage nach erfolgter Speicherung; dies
      gilt nicht, wenn die Aufbewahrung aus Beweisgründen erforderlich ist – die
      Löschung erfolgt dann, wenn der Beweiszweck weggefallen ist.
    </p>
    <h3>7. Rechte der betroffenen Personen</h3>
    <p>
      Nach der DSGVO stehen Ihnen in Bezug auf Ihre Daten verschiedene Rechte
      zu:
    </p>
    <ul>
      <li>
        Sie können eine Bestätigung darüber verlangen, ob betreffende Daten
        verarbeitet werden; wenn dies der Fall ist, können Sie Auskunft über
        diese Daten sowie weitere Informationen und eine Kopie der Daten
        verlangen, Artikel 15 DSGVO.
      </li>
      <li>
        Sie haben das Recht, unverzüglich die Berichtigung von unrichtigen
        personenbezogenen und die Vervollständigung unvollständiger
        personenbezogener Daten zu verlangen, Artikel 16 DSGVO.
      </li>
      <li>
        Sie können verlangen, dass betreffende Daten unverzüglich gelöscht
        (Artikel 17 DSGVO) bzw. in Bezug auf die Verarbeitung eingeschränkt
        werden (Artikel 18 DSGVO.
      </li>
      <li>
        Sie haben unter den Voraussetzungen des Artikels 20 DSGVO das Recht, die
        von Ihnen bereitgestellten Daten zu erhalten und diese Daten einem
        anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln.
      </li>
      <li>
        Sie können gem. Artikel 77 DSGVO eine Beschwerde bei der zuständigen
        Aufsichtsbehörde einreichen.
      </li>
      <li>
        Erteilte Einwilligungen können Sie gem. Artikel 7 Absatz 3 DSGVO mit
        Wirkung für die Zukunft widerrufen und einer künftigen Verarbeitung der
        Sie betreffenden Daten nach Maßgabe des Artikel 21 DSGVO jederzeit
        widersprechen.
      </li>
    </ul>
  </div>
)

export default PrivacyPage
